<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="has-icon mb-4 mt-4">
            <icon variant="price-tag" color="#fff" /> {{ $t("all_products") }}
          </h1>
          <b-select v-model="orderBy" class="mb-4">
            <b-select-option :value="{ key: 'created_at', value: 'desc' }">
              Newer first
            </b-select-option>
            <b-select-option :value="{ key: 'created_at', value: 'asc' }">
              Older first
            </b-select-option>
          </b-select>
        </div>
        <template v-if="isLoading">
          <div class="col-6 col-sm-6 col-lg-3" v-for="n in 8" :key="n">
            <productCardSkeleton />
          </div>
        </template>
        <template v-if="!isLoading && products.length">
          <div
            class="col-6 col-sm-6 col-lg-3"
            v-for="p in products"
            :key="p.id"
          >
            <productCard :product="p" />
          </div>
          <div class="d-flex justify-content-center mt-4 pt-4">
            <b-button
              v-show="!hideLoadMoreButton"
              style="height:46px; width:280px;line-height: initial;"
              variant="primary"
              :disabled="showMoreLoading"
              @click="getMoreProducts()"
            >
              <b-spinner
                style="width: 24px; height: 24px;"
                variant="warning"
                v-if="showMoreLoading"
              ></b-spinner>
              <span v-else>
                {{ $t("load_more") }}
              </span>
            </b-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { productCardSkeleton } from "@/components/skeletons";
import productCard from "@/components/lists/product-list-card.vue";
export default {
  data() {
    return {
      products: [],
      error: null,
      showMoreLoading: false,
      isLoading: false,
      lastProduct: null,
      hideLoadMoreButton: false,
      perPage: 8,
      orderBy: { key: "created_at", value: "desc" },
    };
  },
  components: {
    productCardSkeleton,
    productCard,
  },
  watch:{
    orderBy:{
      deep:true,
      handler(){
        this.getProducts();
      }
    }
  },
  methods: {
    getMoreProducts() {
      this.showMoreLoading = true;
      var db = firebase.firestore();
      db.collection("products")
        .orderBy(this.orderBy.key, this.orderBy.value)
        .startAfter(this.lastProduct)
        .limit(this.perPage)
        .get()
        .then((snapshot) => {
          // this.products = [];
          snapshot.forEach((doc) => {
            this.products.push(doc.data());
          });

          if (snapshot.docs.length < this.perPage) {
            this.hideLoadMoreButton = true;
          }
          this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
          this.showMoreLoading = false;
        })
        .catch((error) => {
          this.showMoreLoading = true;
          this.error = error;
          console.log(error);
        });
    },
    getProducts() {
      this.isLoading = true;
      var db = firebase.firestore();
      db.collection("products")
        .orderBy(this.orderBy.key, this.orderBy.value)
        .limit(this.perPage)
        .get()
        .then((snapshot) => {
          this.products = [];
          snapshot.forEach((doc) => {
            this.products.push(doc.data());
          });
          this.lastProduct = snapshot.docs[snapshot.docs.length - 1];
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = true;
          this.error = error;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<style lang="scss" scoped></style>
